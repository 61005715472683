import React from 'react';
import ButtonGrid from '../../components/buttonGrid';

export default function Visit() {
  return (
    <div>
      <ButtonGrid
        links={[
          {
            titleKey: 'placesToGo',
            to: {
              pathname: '/visit/external',
              search: '?id=placesToGo',
            },
            svgName: 'tile-places-to-go',
          },
          {
            titleKey: 'thingsToDo',
            to: {
              pathname: '/visit/external',
              search: '?id=thingsToDo',
            },
            svgName: 'tile-things-to-do',
          },
          {
            titleKey: 'events',
            to: {
              pathname: '/visit/external',
              search: '?id=events',
            },
            svgName: 'tile-events',
          },
          {
            titleKey: 'planYourTrip',
            to: {
              pathname: '/visit/external',
              search: '?id=planYourTrip',
            },
            svgName: 'tile-plan-your-trip',
          },
        ]}
        buttonColorStyle='background-color-sky-blue'
      />
    </div>
  );
}
