import React from 'react';
import { Trans } from 'react-i18next';
import {
  APP_TIME_ZONE,
  TIME_DATA_UPDATE_INTERVAL_MS,
  WEATHER_DATA_UPDATE_INTERVAL_MS,
} from '../constants/appConstants';

import logoLGA from '../assets/images/logo-lga.png';
import { WeatherService } from '../services/weatherService';
import { SvgIcon } from '../components/svgIcon';

class WelcomeHeader extends React.Component {
  weatherDataRefreshTimer = null;
  timeDataRefreshTimer = null;

  state = {
    dateString: '',
    timeString: '',
    temperatureString: '',
    weatherIcon: '',
    weatherLoaded: false,
  }

  updateTimeInformation = () => {
    clearTimeout(this.timeDataRefreshTimer);

    const timezoneTime = new Date().toLocaleString("en-US", { timeZone: APP_TIME_ZONE });
    const currentDate = new Date(timezoneTime);
    this.setState({
      dateString: currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
      timeString: currentDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', }),
    }, () => {
      setTimeout(this.updateTimeInformation, TIME_DATA_UPDATE_INTERVAL_MS);
    });
  }

  updateWeatherInformation = () => {
    clearTimeout(this.weatherDataRefreshTimer);

    WeatherService.getNewYorkWeather()
      .then(data => {
        this.setState({
          temperatureString: data.temperatureStr,
          weatherIcon: data.weatherIcon,
          weatherLoaded: true,
        }, () => {
          setTimeout(this.updateWeatherInformation, WEATHER_DATA_UPDATE_INTERVAL_MS);
        });
      })
      .catch(_ => {
        this.setState({
          weatherLoaded: true,
        })
        // suppress weather silently, just use old value
        setTimeout(this.updateWeatherInformation, WEATHER_DATA_UPDATE_INTERVAL_MS);
      });
  }

  componentDidMount = () => {
    this.updateTimeInformation();
    this.updateWeatherInformation();
  }

  componentWillUnmount = () => {
    clearTimeout(this.weatherDataRefreshTimer);
    clearTimeout(this.timeDataRefreshTimer);
    this.weatherDataRefreshTimer = null;
    this.timeDataRefreshTimer = null;
  }

  render() {
    const positionClass = this.props.isHomePage ? 'c-welcome-container-home-height' : 'c-welcome-container-normal-height';
    const dateWeatherString = this.state.temperatureString ? "dateWeatherStatus" : "dateStatus";

    return (
      <div className={`c-welcome-header-container ${positionClass}`}>
        <div className='c-welcome-header'>
          <div>
            <img
              src={logoLGA}
              alt={'Logo'}
            />
          </div>
          {!this.props.isHomePage &&
            <div className='l-text-align__right'>
              <strong className='color-white t-semibold__26'>
                <Trans i18nKey='welcomeToNewYork' />
              </strong>
              <br />
              <span className='color-white t-base__20'>
                <Trans i18nKey='dateFormat' values={{
                  dateString: this.state.dateString,
                  timeString: this.state.timeString,
                }} />
              </span>
              <br />
              <span className='c-welcome-header-weather-row color-white t-base__20'>
                {this.state.weatherIcon ? (
                  <SvgIcon name={this.state.weatherIcon} height='20px' />
                ) : (
                    <span />
                  )}
                <span className="l-margin__left5">
                  {this.state.temperatureString}
                </span>
              </span>
            </div>
          }
        </div>
        {this.props.isHomePage &&
          <React.Fragment>
            <div className='c-welcome-header l-margin__top50'>
              <span className='color-white t-semibold__26'>
                <Trans i18nKey='welcomeToNewYorkWithPeriod' />
              </span>
            </div>
            <div className='c-welcome-header l-margin__top16'>
              <span className="l-flex__start-row color-white t-base__20">
                {this.state.weatherLoaded && <Trans i18nKey={dateWeatherString}>
                  Today is <strong>{{ dateStr: this.state.dateString }}</strong>.
                  It is <strong>{{ timeStr: this.state.timeString }}</strong> and it's
                  {this.state.weatherIcon ? (
                    <SvgIcon name={this.state.weatherIcon} height="28px" />
                  ) : (
                      <span />
                    )}
                  <strong>{{ temperatureStr: this.state.temperatureString }}</strong>
                </Trans>}
              </span>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default WelcomeHeader;
